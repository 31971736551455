<template>
  <section>
    <menu-discount-timers-page :group-id="route.meta.ID as GUID" />
    <div class="v-container">
      <menu-product-in-list-page />
    </div>
    <lazy-menu-recommended />
    <div class="v-container">
      <lazy-menu-related />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { GUID } from '@arora/common'

const route = useRoute()
</script>
