<i18n>
ru:
  favoritesSticker: Избранное
ua:
  favoritesSticker: Вибране
us:
  favoritesSticker: Favorites
</i18n>

<template>
  <template
    v-if="
      appConfig.VueSettingsPreRun.MenuStickersShowInGroups &&
      appConfig.StickerTags &&
      appConfig.StickerTags[menuStore.CurrentSubgroupId ?? menuStore.CurrentGroupId]
    "
  >
    <div class="v-filters-wrapper v-mb-sm v-d-flex">
      <lazy-menu-filters-ingredients-azanulbizar
        v-if="appConfig.VueSettingsPreRun.MenuIngredientsLayout === 'Azanulbizar'"
      />
      <lazy-menu-filters-ingredients-fangorn
        v-else-if="appConfig.VueSettingsPreRun.MenuIngredientsLayout === 'Fangorn'"
      />

      <lazy-menu-sorting-products-page />
    </div>
    <transition
      mode="out-in"
      name="slidedown"
    >
      <div
        v-if="
          appConfig.StickerTags &&
          appConfig.StickerTags[menuStore.CurrentSubgroupId ?? menuStore.CurrentGroupId]
        "
        class="v-flex-100"
      >
        <lazy-menu-filters-stickers-mirkwood
          v-if="appConfig.VueSettingsPreRun.MenuStickersLayout === 'Mirkwood'"
        />
        <lazy-menu-filters-stickers-nan-curunir
          v-if="appConfig.VueSettingsPreRun.MenuStickersLayout === 'NanCurunir'"
        />
      </div>
    </transition>
  </template>

  <div
    v-else
    class="v-flex-100 v-d-flex v-flex-row v-justify-content-between v-mb-sm"
  >
    <lazy-menu-filters-ingredients-azanulbizar
      v-if="appConfig.VueSettingsPreRun.MenuIngredientsLayout === 'Azanulbizar'"
    />
    <lazy-menu-filters-ingredients-fangorn
      v-else-if="appConfig.VueSettingsPreRun.MenuIngredientsLayout === 'Fangorn'"
    />

    <lazy-menu-sorting-products-page />
  </div>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const menuStore = useMenuStore()
</script>

<style lang="scss">
@use '~/assets/mixins';

.v-filters-wrapper {
  gap: 8px;
  flex: 0 0 50%;
  max-width: 50%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;

  @include mixins.sm {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
  }
}
</style>
